import React, { useEffect, useState } from "react";
import {
  Button,
  Form as AntForm,
  Modal,
  Upload,
  Input,
  DatePicker,

} from "antd";
import { Form, MainTable } from "components";
import { UploadOutlined } from "@ant-design/icons";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function News() {
  const [data, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [form] = AntForm.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/news`, // API to fetch all news data
    })
      .then((res) => {
        setNewsData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };
const columns=[
            {
              title: "Гарчиг",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Нийтлэх огноо",
              dataIndex: "postDate",
              key: "postDate",
              render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (text, row) => (
                <Button size="small" onClick={() => handleEdit(row)}>
                  Засах
                </Button>
              ),
            },
          ]
  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
    form.setFieldsValue({
      ...row,
      postDate: row.postDate ? moment(row.postDate) : null,
    });
  };

  const fields = [
    {
      label: "Гарчиг",
      name: "title",
      rules: [{ required: true, message: "Гарчиг оруулна уу!" }],
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нийтлэл",
      name: "content",
      rules: [{ required: true, message: "Нийтлэл оруулна уу!" }],
      type:"editor",
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
        type: "textarea",
      },
    },
    {
      label: "Нийтлэх огноо",
      name: "postDate",
      className: "col-span-12 mb-0",
      inputProps: {
        showTime: true,
        format: "YYYY-MM-DD HH:mm",
      },
      render: () => (
        <DatePicker showTime format="YYYY-MM-DD HH:mm" className="w-full" />
      ),
    },
    {
      label: "Зураг",
      name: "image",
      type: "file",
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
      render: () => (
        <Upload
          name="image"
          listType="picture"
          maxCount={1}
          beforeUpload={() => false} // Prevents auto-upload, use form submission instead
        >
          <Button icon={<UploadOutlined />}>Зураг сонгох</Button>
        </Upload>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "image" && values.image?.file) {
        formData.append(key, values.image.file);
      } else if (key === "postDate") {
        formData.append(
          key,
          values.postDate ? values.postDate.format("YYYY-MM-DD HH:mm") : ""
        );
      } else {
        formData.append(key, values[key] || "");
      }
    });

    if (editData) {
      formData.append("id", editData.id);
      instance({
        method: "put",
        url: "/news", // API to update news
        data: formData,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/news", // API to create new news
        data: formData,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          // setCurrentPage={setCurrentPage}
          loading={loading}
          // pagination={true}
          title={"Мэдээ"}
          handleAdd={handleAdd}
        />
      </div>
      <Modal
        title={editData ? "Мэдээ засах" : "Шинэ мэдээ"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={handleCloseModal}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default News;
