import {
  Dashboard,
  Employee,
  Doctor,
  Partner,
  News,
  NonCommercial,
  CommercialFinished,
} from "modules/admin";

export default [
  {
    name: "Хянах самбар",
    path: "/",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер"],
  },
  {
    name: "doctor",
    path: "/doctor",
    element: <Doctor />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "news",
    path: "/news",
    element: <News />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Main",
    path: "/finished",
    element: <CommercialFinished />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Main",
    path: "/noncommercial",
    element: <NonCommercial />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Өрсөлдөгч",
    path: "/partner",
    element: <Partner />,
    role: ["CEO", "Админ", "Менежер"],
  },

  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Employee />,
  },
  // Тохирг
];
